/* CategoryProducts.css */


.product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 30px; 
    padding: 40px; 
    background-color: #f8f9fa;
}

.product-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #e0e0e0; 
    border-radius: 1px; 
    overflow: hidden;
    background-color: #ffffff8f;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    transition: transform 0.3s ease, box-shadow 0.3s ease; 
    backdrop-filter: blur(10px);
  
}

.product-card:hover {
    transform: translateY(-10px); 
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.662);
}

.product-card img {
    width:fit-content(100%); 
    height: 220px;
    object-fit: cover;
    border-bottom: 1px solid #ddd;
    margin-bottom: 15px;
    transition: transform 1s ease;
    
    
}

.product-card img:hover {
    transform: scale(1.1); 
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.096);
}
.product-card img:active {
    transform: scale(1.2);
    transform-origin: center;
   /* height:100%; */
    object-fit: contain;
    
   
}

.product-card h3 {
    font-size: 1.4rem; 
    margin: 1px;
    color: #797474; 
    font-weight: 800; 
    transition: color 0.3s ease, background-clip 0.3s;
    background: none; 
    -webkit-background-clip: text; 
    -webkit-text-fill-color: currentColor;
    text-fill-color: currentColor;
}

.product-card h3:hover {
    background: linear-gradient(to right, #797474,indigo); 
    -webkit-background-clip: text; 
    -webkit-text-fill-color: transparent; 
    color: transparent; 
}


.product-card .price {
    font-size: 1.2rem;
    color: #e74c3c; 
    margin: 1px 0;
    font-weight: 700; 
    
}

.product-card p {
    margin: 1px 0;
    color: #666; 
    font-size: 0.95rem; 
}

.product-card button {
   margin:10px;
    background-color:#0c7d6d; 
    color: #fff;
    border: none;
    padding: 12px 25px; 
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: 600; 
    transition: background-color 0.3s ease, transform 0.3s ease; 
}

.product-card button:hover {
    background-color: #017d6d;
    transform: translateY(-2px); 
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.255);
}

.sort-controls {
    display: flex;
    justify-content: flex-end; 
    align-items: center;
    margin: 10px 0;
    margin-right: 2.4rem;
}

.sort-controls label {
    margin-right: 15px; 
    font-size: 1.1rem;
    color: #333; 
    font-weight: 500;
}

.sort-controls select {
    padding: 8px 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    background-color: #fff;
    color: #333;
    transition: border-color 0.3s ease;
}

.sort-controls select:hover {
    border-color: #999; 
}
