.table-container {
    width: 70%;
    margin: 20px auto;
    padding: 15px;  
    padding-top: 0.4px;
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(31, 1, 255, 0.192);
}

/* Genel form elemanları için stil */
 select {
    padding: 9px;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    font-size: 14px;
    width: 100%; /* Opsiyonel: tüm alanları kapsayacak şekilde genişletir */
    margin-bottom: 10px; /* Altına boşluk ekler */
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.post-table {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
}

.post-table th,
.post-table td {
    border: 2px solid #ddd;
    padding: 6px;
    text-align: center;
}

.post-table th {
    background-color: #f4f4f4;
    color: #333;
    text-align: center;
}

.post-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.post-table tr:not(:hover) {
    backdrop-filter: blur(10px);
    
}

.post-table tr:hover {
    background-color: #5e67c81e;
    box-shadow: 0 0 10px rgba(31, 1, 255, 0.192);
   
}

.custom-icon-button:not(:last-of-type) {
    margin-right: 1rem;
}

.custom-icon-button {
    background-color: #3697ff22;
    color: #3697ff;
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 100px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.custom-icon-button:hover {
    background-color: #3697ffc0;
    color: #fafafa;
}

.add-product-button {
    margin: 20px 0;
    padding: 6px;
    cursor: pointer;
    background-color: #007bff;
    color: rgba(255, 255, 255, 0.803);
    border: none;
    border-radius: 4px;
    box-shadow: 0 3px #999;
}

.add-product-button::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300%;
    height: 300%;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
    transition: transform 0.6s ease, opacity 0.6s ease;
    pointer-events: none;
}

.add-product-button:active::before {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
    /* transform: translateY(4px);*/
}

.add-product-button:hover {
    background-color: #007bffdf;
    border-radius: 4px;
}
