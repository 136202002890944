.navbar {
    background-color: #1a1a1a;
    padding: 18px 24px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid #333;
}

.navbar-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar-item {
    margin: 0 25px;
    position: relative;
}

.navbar-link {
    color: #e0e0e0;
    text-decoration: none;
    font-size: 18px;
    font-weight: 600;
    transition: color 0.2s ease-in, border-bottom 0.2s ease-in, transform 0.2s ease-in;
    padding: 12px 8px;
    position: relative;
}

.navbar-link:hover {
    color: #f39c12;
    border-bottom: 2px solid #f39c12;
    transform: translateY(-2px);
}

.dropdown {
    position: relative;
}

.dropdown-menu {
    list-style: none;
    margin: 10px;
    padding: 0;
    position: absolute;
    top: 34px;
    left: -60px; 
    background-color: #fff;
    color: #333;
    border: 1px solid #ddd;
    border-radius: 8px;
    width: 200px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px); 
    transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease;
}


.dropdown:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

.dropdown-item {
    padding: 14px 24px;
    border-bottom: 0.4px solid #f0f0f0;
    transition: background-color 0.3s ease;
}

.dropdown-item:last-child {
    border-bottom: none;
    height:20px;
}

.dropdown-link {
    text-decoration: none;
    color: #333;
    display: block;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.dropdown-link:hover {
  
    color: #f39c12;
    border-radius: 4px;
   
}

.custom-icon-button-basket {
    background-color: #333;
    color: #f39c12;
    width: 36px;
    height: 36px;
    border: none;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.custom-icon-button-basket:hover {
    color: #fff;
    background-color: #f39c12;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(6px) saturate(0.8);
    z-index: 1000;
}

.modal-content {
    background-color: #fff;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    position: relative;
}
