.profile-container {
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 90%;
    margin: 20px auto;
    text-align: left;
    background: white;
    border: 1px solid #ddd;
}

.profile-container h1 {
    font-size: 28px;
    margin-bottom: 20px;
    color: #4c1717;
    font-weight: 600;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    padding-left: 10px;
}

.profile-container p {
    font-size: 16px;
    margin: 10px 0;
    color: #666;
    padding-left: 10px;
}

.profile-container p.email {
    font-weight: 500;
    color: #2c3e50;
    font-size:larger;
}

.profile-container p.username {
    font-size: 18px;
    color: #34495e;
}

.order-summary {
    cursor: pointer;
    background-color: #e9ecef;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    transition: background-color 0.3s;

}

.order-summary:hover {
    background-color: #ddd;
}

.order-details {
    padding: 10px;
    border-left: 2px solid #3498db;
    background-color: #f9f9f9;
    border-radius: 8px;

}

.product-image {
    max-width: 100px;
    height: auto;
    border-radius: 4px;
    margin-right: 10px;
    vertical-align: middle;

}

.product-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.product-info {
    flex: 1;
    padding-left: 10px;
}

.product-image+.product-info {
    text-align: left;

}
.image-container ul {
    display: flex; 
    flex-wrap: wrap; 
    list-style: none; 
    padding: 0;
    margin: 0;
}

.image-container .image-item {
    margin-right: 10px; 
}

.image-container img {
    max-width: 30px;
    height: 30px;
    border-radius: 4px;
    vertical-align: middle;
}


ul {
    padding-left: 5px;
    list-style: none;
}

li {
    margin-bottom: 10px;
}