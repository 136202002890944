/* Grid Düzeni */
.product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Eşit aralıklarla düzenlenen sütunlar */
    gap: 20px; /* Ürünler arasında boşluk */
    padding: 20px; /* Grid çevresinde iç boşluk */
    
}

/* Ürün Kartı */
.product-card{
    display: flex;
    flex-direction: column;
    align-items: center; 
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    background-color: #fff;
    padding: 10px;
    text-align: center;
    max-width: 100%; 

    
    
}
.notification {
    position: fixed;
    top: 150px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #af4c4c;
    color: white;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
    z-index: 1000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }


.product-card img {
    width: 100%; 
    height: 200px; 
    object-fit: cover; 
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
}


.product-card h3 {
    font-size: 1.2rem;
    margin: 10px 0;
}

/* Ürün Fiyatı */
.product-card .price {
    font-size: 1rem;
    color: #f60 !important;
    margin: 10px 0;
}

.product-card p {
    margin: 10px 0;
}

.product-card button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
}


.product-card button:hover {
    background-color: #0056b3;
}
.search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    
  }
  
  .search__input {
   
    font-family: inherit;
    font-size: inherit;
    background-color: #f4f2f2;
    border: none;
    color: #646464;
    padding: 0.7rem 1rem;
    border-radius: 30px;
    width: 12em;
    transition: all ease-in-out .5s;
    margin-right: -2rem;
    margin-top:-85%;    
    
  }
  
#special-page .header-container {
    position: absolute;
  }

  