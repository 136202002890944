.product-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.product-page img {
    width: 100%;
    height: auto;
    max-height: 400px;
    object-fit: contain;
    border-radius: 8px;
    margin-bottom: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product-page img:hover {
    transform: scale(1.05);
   
}

.product-page h1 {
    font-size: 2rem;
    color: #333;
    margin: 10px 0;
    text-align: center;
}

.product-page p {
    font-size: 1rem;
    color: #555;
    margin: 5px 0;
    text-align: center;
}

.product-page .price {
    font-size: 1.2rem;
    color: #e74c3c;
    font-weight: bold;
    margin: 10px 0;
    font-weight: bolder;
    text-align: center;
}

.product-page button {
    background-color: #28a745;
    color: #fff;
    border: none;
    padding: 12px 25px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin-top: 20px;
    display: block;
}

.product-page button:hover {
    background-color: #218838;
    transform: translateY(-2px);
}

.product-page .back-button {
    margin-top: 10px;
    background-color: #007bff;
    border-radius: 5px;
    padding: 10px 20px;
    color: #fff;
    text-decoration: none;
    font-size: 0.9rem;
    font-weight: 600;
    display: block;
    text-align: center;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.product-page .back-button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
}
